import * as React from 'react'
import { EndlessHolidayProduct } from '@modules/endless-holiday/models'
import { Checkbox } from '@components/controls/checkbox'
import { formatPriceShort } from '@helpers/price-helper'
import { EndlessHolidayFormInputProduct, EndlessHolidayFormInputs } from '@modules/endless-holiday/details'
import { useFormContext, useWatch } from 'react-hook-form'
import { CustomTooltip } from '@components/custom-tooltip'

export const STAY_DATE_CHANGE_OPTION_PRICE = 99

interface Props {
  subscriptionProduct: EndlessHolidayProduct
}

export const ProductVariantStayOptions = ({ subscriptionProduct }: Props): React.ReactNode => {
  const { control, setValue } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const isChecked = products.some(
    (product: EndlessHolidayFormInputProduct) =>
      product.product.id === subscriptionProduct.id &&
      product.options.some(option => option.kind === 'single_client_date'),
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      'products',
      products.map((product: EndlessHolidayFormInputProduct) => {
        if (product.product.id === subscriptionProduct.id) {
          return {
            ...product,
            options: event.target.checked
              ? [{ kind: 'single_client_date' as const, price: STAY_DATE_CHANGE_OPTION_PRICE }]
              : [],
          }
        }

        return product
      }),
    )
  }

  return (
    <div className="border rounded bg-white p-2 mt-3">
      <Checkbox
        controlled
        className="checkbox--small user-select-none"
        name={`options.${subscriptionProduct.id}`}
        checked={isChecked}
        onChange={handleChange}
        label={
          <span className="font-sm font-500 d-flex align-items-center">
            Chcę dokupić opcję zmiany daty za {formatPriceShort(STAY_DATE_CHANGE_OPTION_PRICE)}
            <CustomTooltip
              showOnHover
              tooltipClassName="p-0"
              content={
                <div style={{ width: 200 }} className="p-2">
                  <strong>Jednorazowa</strong> możliwość zmiany terminu rezerwacji z użyciem{' '}
                  <span className="text-nowrap">ww. kodu pobytowego</span>
                </div>
              }
            >
              <i className="uil-info-circle font-lg ms-1 lh-0" />
            </CustomTooltip>
          </span>
        }
      />
    </div>
  )
}
