import * as React from 'react'
import { useSelector } from 'react-redux'
import { useFormRequest } from '@hooks/use-form-request'
import { AppFooter } from '@components/app-footer'
import { AppHeaderSection } from '@components/app-header-section'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { ResortsGallery } from '@components/resorts-gallery'
import { useProductDetails } from '@hooks/use-product-details'
import { ResortImages } from '@modules/package-wholesale/details'
import { EndlessHolidayDetailsHeaderSectionContent } from '@modules/endless-holiday/details/header-content'
import {
  EndlessHolidayConfirmationDetails,
  EndlessHolidayDetails,
  EndlessHolidayProduct,
  EndlessHolidayProductOption,
} from '@modules/endless-holiday/models'
import { EndlessHolidayDetailsContent } from '@modules/endless-holiday/details/content'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { SubmitActionButtonContent } from '@components/submit-action-row'
import { EndlessHolidayManualBox } from '@modules/endless-holiday/details/endless-holiday-manual-box'
import { PersonalData } from '@components/personal-data/personal-data'
import { EndlessHolidaySideCardSummary } from '@modules/endless-holiday/details/side-card/side-card-summary'
import { Accommodation } from '@modules/endless-holiday/details/products-selection/accommodation-type-selection'
import { commonObjectPost } from '@requests/basic-requests'
import { selectClientDetails } from '@store/slices/client-slice'
import { ClientInvoiceData } from '@models/models'
import { useEndlessHolidayTotalPrice } from '@modules/endless-holiday/details/side-card/use-total-price'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export interface EndlessHolidayFormOption {
  price: string | number
  kind: EndlessHolidayProductOption
}

export interface EndlessHolidayFormInputProduct {
  product: EndlessHolidayProduct
  amount: number
  related_product: number | null
  options: EndlessHolidayFormOption[]
}

export interface EndlessHolidayFormInputs extends ClientInvoiceData {
  products: EndlessHolidayFormInputProduct[]
  accommodation: Accommodation
  first_name: string
  last_name: string
  create_account: boolean
  new_account_password: string
  email: string
}

export const EndlessHolidayDetailsView = (): React.ReactNode => {
  const { scrollTo } = useScrollIntoView()
  const clientDetails = useSelector(selectClientDetails)
  const navigate = useNavigate()

  const { product_packages } = useSelector(selectAppDataUrls)
  const { productDetails, PageWrapper } = useProductDetails<EndlessHolidayDetails>(product_packages.invite_details)

  const resortGalleryRef = React.useRef<HTMLDivElement>(null)
  const detailsRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<EndlessHolidayFormInputs>({ defaultValues: { products: [], accommodation: 'apartment' } })
  const products = useWatch({ control: methods.control, name: 'products' })
  const { totalPrice } = useEndlessHolidayTotalPrice(products, productDetails?.can_use_first_price_brutto ?? false)

  const { action: onSubmit, isLoading } = useFormRequest(
    async (formData: EndlessHolidayFormInputs) => {
      if (!productDetails) return

      const rules = [...productDetails.required_rules, ...productDetails.optional_rules].reduce(
        (acc, rule) => ({ ...acc, [rule]: formData[rule] }),
        {},
      )

      const clientData = clientDetails || formData

      const isWithClientDateChange = (product: EndlessHolidayFormInputProduct) =>
        product.options.some((option: EndlessHolidayFormOption) => option.kind === 'single_client_date')

      const payload = {
        products: formData.products.map(product => ({
          product: product.product.id,
          amount: product.amount,
          related_product: product.related_product,
          single_client_date: isWithClientDateChange(product),
        })),
        name: `${clientData.first_name} ${clientData.last_name}`,
        email: clientDetails ? clientDetails.email : formData.email,
        ...(formData.create_account && {
          create_account: formData.create_account,
          new_account_password: formData.new_account_password,
        }),
        ...(formData.invoice && {
          invoice: formData.invoice,
          invoice_city: formData.invoice_city,
          invoice_nip: formData.invoice_nip,
          invoice_type: formData.invoice_type,
          invoice_company: formData.invoice_company,
          invoice_postcode: formData.invoice_postcode,
          invoice_street: formData.invoice_street,
        }),
        ...rules,
      }

      const { token } = await commonObjectPost<EndlessHolidayConfirmationDetails>(productDetails.urls.create, payload)
      navigate(NavigationPath.EndlessHolidayConfirmation.replace(':token', token))
    },
    methods.setError,
    { nonFieldErrorsAs: 'products_selection' },
  )

  return (
    <PageWrapper>
      {productDetails && (
        <>
          <AppHeaderSection images={ResortImages} imageDescription="Wakacje w nieskończoność">
            <EndlessHolidayDetailsHeaderSectionContent onScrollToDetails={scrollTo(detailsRef)} />
          </AppHeaderSection>

          <div className="container app-header-section__after-content">
            <FormProvider {...methods}>
              <form
                onSubmit={e => {
                  methods.clearErrors()
                  methods.handleSubmit(onSubmit)(e)
                }}
              >
                <div className="row gx-xl-5">
                  <div className="card col-lg-9 col-12 py-4 card-shadow border-0 overflow-hidden">
                    <EndlessHolidayDetailsContent
                      ref={detailsRef}
                      productDetails={productDetails}
                      onScrollToResorts={scrollTo(resortGalleryRef)}
                    />
                    <EndlessHolidayManualBox />
                    <PersonalData
                      title={
                        <p className="font-xxl text-secondary text-center text-lg-start">
                          Zamów swoje <span className="font-500 text-nowrap">Wakacje w nieskończoność</span>
                        </p>
                      }
                    />
                    <DynamicAgreementsSection
                      title=""
                      productRules={[...productDetails.required_rules, ...productDetails.optional_rules]}
                    />
                    <PaymentActionRow
                      className="mt-5"
                      amount={totalPrice}
                      isLoading={isLoading}
                      buttonContent={
                        <SubmitActionButtonContent mainText="Potwierdzam zamówienie" subText="z obowiązkiem zapłaty" />
                      }
                    />
                  </div>
                  <div className="d-flex flex-column col-3">
                    <EndlessHolidaySideCardSummary productDetails={productDetails} />
                  </div>
                </div>
              </form>
            </FormProvider>

            <ResortsGallery
              ref={resortGalleryRef}
              wrapperClassName="bocian-voucher__gallery"
              customBreakpoints={breakpoints}
              title="Odwiedź nas"
              subtitle={
                <p className="text-darker-gray">
                  Mamy aż 7 lokalizacji do wyboru <strong>nad morzem lub w górach</strong>
                </p>
              }
            />
          </div>

          <AppFooter />
        </>
      )}
    </PageWrapper>
  )
}

const breakpoints = {
  extraLarge: { breakpoint: { max: 3000, min: 1770 }, items: 5 },
  large: { breakpoint: { max: 1770, min: 1200 }, items: 5 },
  semiLarge: { breakpoint: { max: 1200, min: 768 }, items: 3 },
  medium: { breakpoint: { max: 768, min: 468 }, items: 2 },
  small: { breakpoint: { max: 468, min: 0 }, items: 1 },
}
