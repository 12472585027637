import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { EndlessHolidaySideCardGastroCardSummaryRow } from '@modules/endless-holiday/details/side-card/gastro-card-summary-row'
import { EndlessHolidayFormInputProduct } from '@modules/endless-holiday/details'
import { EndlessHolidayProduct } from '@modules/endless-holiday/models'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { EndlessHolidaySideCardDepositSummaryRow } from '@modules/endless-holiday/details/side-card/deposit-summary-row'
import { EndlessHolidaySideCardOptionSummaryRow } from '@modules/endless-holiday/details/side-card/option-summary-row'

interface Props {
  year: string
  products: EndlessHolidayFormInputProduct[]
  gastroCardProducts: EndlessHolidayFormInputProduct[]
  showDiscountedPrice: boolean
}

export const EndlessHolidaySideCardSubscriptionSummaryRow = ({
  year,
  products,
  gastroCardProducts,
  showDiscountedPrice,
}: Props): React.ReactNode => {
  const getProductGastroCards = (product: EndlessHolidayProduct) =>
    gastroCardProducts.filter(p => p.related_product === product.id)

  const gerProductName = (product: EndlessHolidayProduct) => {
    const accommodationType = ACCOMMODATION_TYPES.HOUSES.includes(product.accommodation_type) ? 'Domek' : 'Apartament'

    return {
      gold: `${accommodationType} - Pakiet złoty`,
      silver: `${accommodationType} - Pakiet srebrny`,
      brown: `${accommodationType} - Pakiet brązowy`,
    }[product.subscription_kind]
  }

  return (
    <li className="font-sm px-3 mt-2">
      <span className="font-500 font-lg">W roku {year}</span>

      <div className="d-flex flex-column gap-2">
        {products.map(product => (
          <div key={product.product.id}>
            <div className="d-flex justify-content-between align-items-center font-sm">
              <div className="font-xs font-500 col-8 pe-4 text-truncate">{gerProductName(product.product)}</div>
              <div className="col-1">{product.amount}x</div>
              <div className="col-3 text-end font-500">
                {formatPrice(showDiscountedPrice ? product.product.first_price_brutto : product.product.price_brutto)}
              </div>
            </div>

            {getProductGastroCards(product.product).map(gastroProduct => (
              <EndlessHolidaySideCardGastroCardSummaryRow
                key={gastroProduct.product.id}
                amount={product.amount}
                gastroProduct={gastroProduct.product}
                showDiscountedPrice={showDiscountedPrice}
              />
            ))}

            <EndlessHolidaySideCardDepositSummaryRow subscriptionProduct={product.product} amount={product.amount} />

            {product.options.map(option => (
              <EndlessHolidaySideCardOptionSummaryRow key={option.kind} option={option} amount={product.amount} />
            ))}
          </div>
        ))}
      </div>
    </li>
  )
}
